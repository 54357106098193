import 'unpoly/unpoly.min'
import hyperform from 'hyperform'
import Cleave from 'cleave.js'
require('cleave.js/dist/addons/cleave-phone.ch')

up.compiler('body', () => {
  if (window.matchMedia('(min-width: 1024px)').matches) {
    up.motion.config.enabled = true
  } else {
    up.motion.config.enabled = false
  }
})

up.compiler('a[up-transition]', (element) => {
  up.motion.config.duration = 800
})

up.animation('flip-old-left', function(element, options) {
  const page = element.querySelector('.js-page')
  page.style.transform = 'rotateY(0)'
  page.style.maxHeight = 'calc(900px - 2rem)'
  page.style.overflow = 'hidden'
  return up.animate(page, {
    transform: 'rotateY(-180deg)'
  }, options)
})

up.animation('flip-new-left', function(element, options) {
  const page = element.querySelector('.js-page')
  page.style.transform = 'rotateY(180deg)'
  return up.animate(page, {
    transform: 'rotateY(0)'
  }, options)
})

up.animation('flip-old-right', function(element, options) {
  const page = element.querySelector('.js-page')
  page.style.transform = 'rotateY(0)'
  element.style.zIndex = 30
  return up.animate(page, {
    transform: 'rotateY(180deg)'
  }, options)
})

up.animation('flip-new-right', function(element, options) {
  const page = element.querySelector('.js-page')
  page.style.transform = 'rotateY(-180deg)'
  page.style.maxHeight = 'calc(900px - 2rem)'
  page.style.overflowY = 'hidden'
  element.style.zIndex = 30
  return up.animate(page, {
    transform: 'rotateY(0)'
  }, options)
})

up.animation('darken', function(element, options) {
  const page = element.querySelector('.js-page')
  element.style.filter = 'brightness(1)'
  element.style.zIndex = 20
  return Promise.all([
    up.animate(element, {filter: 'brightness(0.3)', 'z-index': 10}, options),
    up.animate(page, {'max-height': 'calc(900px - 2rem)', overflow: 'hidden'}, options)
  ])
})

up.animation('lighten', function(element, options) {
  const page = element.querySelector('.js-page')
  element.style.filter = 'brightness(0.3)'
  element.zIndex = 10
  return Promise.all([
    up.animate(element, {filter: 'brightness(1)', 'z-index': 20}, options),
    up.animate(page, {'max-height': 'calc(900px - 2rem)', overflow: 'hidden'}, options)
  ])
})

up.transition('flip-left', (oldElement, newElement, options) => {
  if (oldElement.classList.contains('js-header')) {
    return Promise.all([
      up.animate(oldElement, 'darken', options),
      up.animate(newElement, 'flip-new-left', options)
    ])
  } else if (oldElement.classList.contains('js-article')) {
    return Promise.all([
      up.animate(oldElement, 'flip-old-left', options),
      up.animate(newElement, 'lighten', options)
    ])
  }
})

up.transition('flip-right', (oldElement, newElement, options) => {
  if (oldElement.classList.contains('js-header')) {
    return Promise.all([
      up.animate(oldElement, 'flip-old-right', options),
      up.animate(newElement, 'lighten', options)
    ])
  } else if (oldElement.classList.contains('js-article')) {
    return Promise.all([
      up.animate(oldElement, 'darken', options),
      up.animate(newElement, 'flip-new-right', options)
    ])
  }
})

up.compiler('.js-phone-field', (element) => {
  new Cleave(element, {
      phone: true,
      phoneRegionCode: 'ch'
  })
})

up.on('up:fragment:inserted', '.js-article',  function(event, element) {
  const page = element.querySelector('.js-page')
  const showOverflow = () => {
    page.style.maxHeight = 'auto'
    page.style.overflow = 'visible'
  }
  setTimeout(showOverflow, 1000)
})

up.compiler('.js-off-canvas', (element) => {
  const offCanvas = element
  const navOpen = document.querySelector('.js-nav-open')
  const navClose = document.querySelector('.js-nav-close')

  navOpen.addEventListener('click', () => offCanvas.classList.remove('-translate-y-full'))
  navClose.addEventListener('click', () => offCanvas.classList.add('-translate-y-full'))
})

up.compiler('.js-contact-form', () => {
  hyperform.addTranslation("de", {
    TextTooLong: "Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    TextTooShort: "Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    ValueMissing: "Bitte füllen Sie dieses Feld aus.",
    InvalidEmail: "Bitte geben Sie eine E-Mail-Adresse ein.",
    PatternMismatch:"Bitte halten Sie sich an das vorgegebene Format.",
    "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
    "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
    "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
    "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
    "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
    "any value": "jeder Wert",
    "any valid value": "jeder gültige Wert",
  });
  hyperform.setLanguage("de");
  hyperform(window, {
    revalidate: 'onblur',
  });
})
